body {
  margin: 0;
  font-family: Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(0, 255, 0);
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

h1 {}

#root {
  width: 100vw;
  height: 100vh;
}

.App {
  max-width: 100%;
  min-height: 100%;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.content {
  padding: 10px;
  height: 100%;
  flex: 1 1 100%;
  border: 1px rgb(0, 255, 0) solid;
  margin: 10px 5px;
}

.description {
  min-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul {
  padding: 0;
}

li {
  list-style: none;
  margin: 10px;

}

a {
  color: rgb(0, 255, 0);
  padding: 5px;
  display: block;
}